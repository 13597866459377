import { useEffect } from 'react';
import React from 'react'




const Mobileapp = () => {

  useEffect(()=> {
    document.title="Mobile App Development Company in Patna - Adhiraj Infotech Call Now +91 9386639817, 8709668144";
    }, []);

  return (
    <>
  <section id="innerPG-banner">
  <div className="container-fluid">
    <div className="row">
      <div
        className="banner-item col-lg-12"
        style={{ backgroundImage: 'url("/assets/images/about-us-banner.jpg")' }}
      >
        <div className="innerBanner-caption">
          <h1>Mobile App Development</h1>
          <p>
          We’re a team of creatives who are excited about new ideas
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

    <section>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <p className='font-serif h-4'>Mobile application development is the process of creating software applications that run on a mobile device, and a typical mobile application utilizes a network connection to work with remote computing resources. Hence, the mobile development process involves creating installable software bundles (code, binaries, assets, etc.) , implementing backend services such as data access with an API, and testing the application on target devices.</p>
            
          </div>
          <div className="col-lg-6">
         
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default Mobileapp
